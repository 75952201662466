<template>
  <div class="table">
    <div class="banner">
      <img src="https://img.kcgeis.com/7378970420.jpg">
    </div>
    <div class="content">
      <h3>
        安雅园建筑新型材料：轻钢建筑材料
      </h3>

      <ElTable
        :data="tableData"
        :span-method="arraySpanMethod"
        border
        stripe
        :cell-style="rowStyle"
        :header-cell-style="headerCellStyle"
      >
        <ElTableColumn prop="type" label="类型" width="150xp" />
        <ElTableColumn prop="name" label="名称" />
        <ElTableColumn prop="scale" label="规格" />
        <ElTableColumn prop="unit" label="单位" width="90xp" />
        <ElTableColumn prop="label" label="备注" />

        <ElTableColumn prop="details" label="详情" width="120px">
          <RouterLink :to="{ path: '/materials' }">
            <ElButton type="text">
              详情
            </ElButton>
          </RouterLink>
        </ElTableColumn>
      </ElTable>
    </div>
  </div>
</template>

<script>
import { FormMerge } from './utils/index';


export default {
  name: 'UtilsIndex',
  data() {
    return {
      tableData: [
        {
          type: '钢材类',
          name: '轻钢龙骨',
          scale: '攀钢、酒钢（Q550-AZ150-C89）',
          unit: '吨',
          label: '原料实价',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '楼承板750型',
          scale: '宽750MM，波高35mm，长定制',
          unit: '米',
          label: '0.8 镀锌275G',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '300C/U型钢',
          scale: '宽300MM，长定制',
          unit: '米',
          label: '1.8镀锌275g(楼承梁)',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '340 L型加固板',
          scale: '40*300*1.0mm',
          unit: '米',
          label: '过梁或者桁架加固',
          details: '详情',
        },
        {
          type: '钢材类',
          name: ' 0.8檐口折件40型',
          scale: '40*180*1.0（120度）',
          unit: '米',
          label: '屋檐竖立面封口',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '小角铁',
          scale: '40*40*0.8mm*3m',
          unit: '米',
          label: '瓦底防垂，楼梯，墙角',
          details: '详情',
        },
        {
          type: '钢材类',
          name: ' 大角铁',
          scale: '90*90*0.8mm*3m',
          unit: '米',
          label: '屋脊，不同屋面交汇处',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '小拉带',
          scale: '40mm*0.8厚',
          unit: '吨',
          label: '屋盖龙骨或者墙体加固',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '大拉带',
          scale: '183mm*0.8厚',
          unit: '吨',
          label: '蒙皮，过梁加固',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '钢板网',
          scale: '2*10米/卷70*100MM',
          unit: '平',
          label: '用于楼面板现浇',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '钢丝网',
          scale: '1*40米/卷4*4.5MM',
          unit: '件',
          label: '用于墙面抹灰',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '有筋扩张网',
          scale: '2.5*06米',
          unit: '平米',
          label: '实心墙体封墙',
          details: '详情',
        },
        {
          type: '钢材类',
          name: '备用龙骨',
          scale: '3m/4m每根*0.8mm厚',
          unit: '米',
          label: '临时支撑、现场加固',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3宝源/园方OSB',
          scale: '9mm（1220*2440）',
          unit: '张',
          label: '内墙、屋顶',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3宝源/园方OSB',
          scale: '12mm（1220*2440）',
          unit: '张',
          label: '外墙、屋顶',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3宝源/园方OSB',
          scale: '18mm（1220*2440）',
          unit: '张',
          label: '楼梯、楼板',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3克诺斯邦OSB',
          scale: '9mm（1220*2440）',
          unit: '张',
          label: '内墙、屋顶',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3克诺斯邦OSB',
          scale: ' 12mm（1220*2440）',
          unit: '张',
          label: '外墙、屋顶',
          details: '详情',
        },
        {
          type: '欧松板',
          name: 'B3克诺斯邦OSB',
          scale: ' 18mm（1220*2440）',
          unit: '张',
          label: '楼梯、楼板',
          details: '详情',
        },
        {
          type: '板材',
          name: '硅酸钙板',
          scale: '6mm（1220*2440）',
          unit: '张',
          label: '内墙、屋顶',
          details: '详情',
        },
        {
          type: '板材',
          name: '硅酸钙板',
          scale: '8mm（1220*2440）',
          unit: '张',
          label: '外墙、屋顶',
          details: '详情',
        },
        {
          type: '板材',
          name: '硅酸钙板',
          scale: '10mm（1220*2440）',
          unit: '张',
          label: '楼梯、楼板',
          details: '详情',
        },
        {
          type: '板材',
          name: '石膏板',
          scale: '1220*2440   9.5mm',
          unit: '张',
          label: '吊顶',
          details: '详情',
        },
        {
          type: '板材',
          name: '水泥纤维板',
          scale: '1220*2440  8mm高压高密',
          unit: '张',
          label: '外墙、檐口、可直接做真石漆',
          details: '详情',
        },
        {
          type: '板材',
          name: '海龙水泥纤维木纹挂板',
          scale: '210*3660  7.5mm高压高密',
          unit: '张',
          label: '高压、低水、不起灰',
          details: '详情',
        },
        {
          type: '保温材料',
          name: '华美玻璃棉15kg',
          scale: '100mm15kg单面铝箔',
          unit: '件',
          label: '12M²/件保温隔热隔音',
          details: '详情',
        },
        {
          type: '保温材料',
          name: '华美玻璃棉20kg',
          scale: '100mm20kg单面铝箔',
          unit: '件',
          label: '12M²/件保温隔热隔音',
          details: '详情',
        },
        {
          type: '保温材料',
          name: 'B2挤塑板4CM',
          scale: '1.2*0.6米',
          unit: '张',
          label: '保温隔热隔音',
          details: '详情',
        },
        {
          type: '保温材料',
          name: 'B2挤塑板2CM',
          scale: '1.2*0.6米',
          unit: '张',
          label: '保温隔热隔音',
          details: '详情',
        },
        {
          type: '保温材料',
          name: 'B1挤塑板4CM',
          scale: '1.8*0.6米',
          unit: '张',
          label: '保温隔热隔音',
          details: '详情',
        },
        {
          type: '保温材料',
          name: 'B1挤塑板2CM',
          scale: '1.2*0.6米',
          unit: '张',
          label: '保温隔热隔音',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '昂世呼吸纸',
          scale: '1.5m*50m',
          unit: '平',
          label: '75M²/件',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '1.5mm防水卷材',
          scale: '/',
          unit: '件',
          label: '20M²/件',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '2.0mm防水卷材',
          scale: '2.0mm自粘',
          unit: '件',
          label: '15M²/件',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '科斯曼沥青瓦',
          scale: '马赛克3M²/件',
          unit: '包',
          label: '中国红、云石灰  海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '欧耐斯沥青瓦',
          scale: '马赛克3M²/件',
          unit: '包',
          label: '中国红、云石灰  海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '博天彩石金属瓦',
          scale: '0.5M²/张   0.4mm厚',
          unit: '张',
          label: '陶土红、海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '博天脊瓦',
          scale: '2m/根',
          unit: '米',
          label: '陶土红、海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '博天水沟',
          scale: '2m/根',
          unit: '米',
          label: '陶土红、海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '博天山墙板',
          scale: '2m/根',
          unit: '米',
          label: '陶土红、海洋蓝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '400g丙纶布',
          scale: '2m/根',
          unit: '卷',
          label: '露台，卫生间，厨房防水材料',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '胶粉',
          scale: '1000克/包',
          unit: '包',
          label: '配合丙纶布使用',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '结构胶',
          scale: '590mL',
          unit: '支',
          label: '防水、勾缝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '泡沫胶',
          scale: '/',
          unit: '支',
          label: '门、窗填缝',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '雨虹JS涂料',
          scale: '44kg组（双组份）',
          unit: '组',
          label: '立面、地面',
          details: '详情',
        },
        {
          type: '防水材料',
          name: '雨虹聚氨酯',
          scale: '20kg桶（单组份）',
          unit: '桶',
          label: '地面',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '丙稀酸外墙漆',
          scale: '20kg桶（多色）',
          unit: '桶',
          label: '地面',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '底漆',
          scale: '10kg/桶',
          unit: '桶',
          label: '挂板底层',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '面漆',
          scale: '10kg',
          unit: '桶',
          label: '挂板最外层抗氧化',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '真石漆',
          scale: '75kg/桶',
          unit: '桶',
          label: '可定制',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '质感涂料',
          scale: '75kg桶（多色）',
          unit: '桶',
          label: '刷檐口、窗框，颜色可定制',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '质感涂料',
          scale: '25kg桶（多色）',
          unit: '桶',
          label: '刷檐口、窗框，颜色可定制',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '木方20*30',
          scale: '2.5m/根',
          unit: '米',
          label: '挂瓦，挤塑板固定',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '木方20*40',
          scale: '2.5m/根',
          unit: '米',
          label: '挂瓦，挤塑板固定',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '文化石',
          scale: '1M²/件',
          unit: '件',
          label: '外墙、基础装饰',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '填缝剂',
          scale: '20kg/包',
          unit: '包',
          label: '勾缝',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '瓷砖胶',
          scale: '20kg/包',
          unit: '包',
          label: '粘贴文化石',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '自粘网格布',
          scale: '/',
          unit: '卷',
          label: '线条接缝处防裂',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: '粘结砂浆',
          scale: '25kg/包',
          unit: '包',
          label: '粘贴EPS线条',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: 'EPS90°阳角线40*80*80mm',
          scale: '1.5m/根',
          unit: '米',
          label: '阳角装饰条',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: 'EPS90°阴角线40*120*120mm',
          scale: '1.5m/根',
          unit: '米',
          label: '阴角装饰条',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: 'EPS裙脚线40*80*110mm',
          scale: '1.5m/根',
          unit: '米',
          label: '裙边装饰条',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: 'EPS腰线40*93*200mm',
          scale: '1.5m/根',
          unit: '米',
          label: '楼层间腰线装饰条',
          details: '详情',
        },
        {
          type: '装饰材料',
          name: 'EPS窗套线40*40*80',
          scale: '1.5m/根',
          unit: '米',
          label: '门套窗套装饰条',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '天沟（彩铝5.5英寸/PVC5.2英寸)',
          scale: '3m/根，收集雨水',
          unit: '米',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '雨水管（彩铝/PVC)',
          scale: '3m/根，引导雨水',
          unit: '米',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '吊件（彩铝/PVC)',
          scale: '固定天沟',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '卡接器（彩铝/PVC)',
          scale: '连接天沟',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '90°阳角（彩铝/PVC)',
          scale: '天沟走线',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '90°阴角（彩铝/PVC)',
          scale: '天沟走线',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '135°阳角（彩铝/PVC)',
          scale: '天沟走线',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '135°阴角（彩铝/PVC)',
          scale: '天沟走线',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '65°正弯（彩铝/PVC)',
          scale: '雨水管导向',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '65°侧弯（彩铝/PVC)',
          scale: '雨水管导向',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '90°正弯（彩铝/PVC)',
          scale: '雨水管导向',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '90°侧弯（彩铝/PVC)',
          scale: '雨水管导向',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '管卡（彩铝/PVC)',
          scale: '固定雨水管',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '斜三通（彩铝/PVC)',
          scale: '多路雨水管交汇处',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '封盖（彩铝/PVC)',
          scale: '天沟起始端末端',
          unit: '对',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '承雨斗/下水器（彩铝/PVC)',
          scale: '天沟与雨水管之间',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '接头（彩铝/PVC)',
          scale: '连接雨水管',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '泛水板（彩铝/PVC)',
          scale: '3m/根',
          unit: '米',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '独立雨水斗（彩铝/PVC)',
          scale: '固定墙上单独承接雨水管',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '落水材料',
          name: '方转圆（彩铝/PVC)',
          scale: '方形水管转圆管',
          unit: '只',
          label: '/',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '龙骨拼装',
          scale: 'M4.8*16细牙（拉丝派特工艺）',
          unit: '支',
          label: '2000支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '龙骨拼装',
          scale: 'M4.8*16粗牙',
          unit: '支',
          label: '2000支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: 'OSB螺丝',
          scale: 'M4.2*32',
          unit: '支',
          label: '1500支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: 'OSB螺丝',
          scale: 'M4.2*38（十字压花）',
          unit: '支',
          label: '1200支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '硅钙板、水泥板',
          scale: 'M4.2*32+翅',
          unit: '支',
          label: '1500支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '硅钙板、水泥板',
          scale: 'M4.2*38+翅',
          unit: '支',
          label: '1200支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '一楼与二楼天地龙骨贯穿楼承板',
          scale: 'M5.5*50（六角）',
          unit: '支',
          label: '350支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '墙体与墙体连接',
          scale: 'M5.5*19（六角）',
          unit: '支',
          label: '900支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '装饰条、挤塑板螺丝',
          scale: 'M4.8*75',
          unit: '支',
          label: '300支/盒',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '化学药水',
          scale: '锚栓药剂',
          unit: '支',
          label: '',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '化学螺杆M16*190',
          scale: 'M16*190',
          unit: '支',
          label: '',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: 'M16*550螺杆',
          scale: 'M16*550',
          unit: '支',
          label: '',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '瓦钉',
          scale: '瓦钉',
          unit: 'kg',
          label: '与沥青瓦配套使用',
          details: '详情',
        },
        {
          type: '螺丝•匹卓',
          name: '抗拔件',
          scale: '',
          unit: '只',
          label: '房屋抗拔',
          details: '详情',
        },
        {
          type: '连接件',
          name: 'Φ18L直角抗拔件',
          scale: '',
          unit: '只',
          label: '与M16*550配套',
          details: '详情',
        },
        {
          type: '连接件',
          name: '抗风件（左/右）',
          scale: '',
          unit: '只',
          label: '桁架加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '旋转件（左/右）',
          scale: '',
          unit: '只',
          label: '桁架加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '角码',
          scale: '',
          unit: '只',
          label: '墙角加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '大紧固件',
          scale: '',
          unit: '只',
          label: '拉带绷紧',
          details: '详情',
        },
        {
          type: '连接件',
          name: 'U型梁连接件',
          scale: '',
          unit: '只',
          label: '悬托梁加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '直板连接件',
          scale: '',
          unit: '只',
          label: '墙体加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: 'T型连接件',
          scale: '',
          unit: '只',
          label: '墙体加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '蝶型接件',
          scale: '',
          unit: '只',
          label: '桁架加固',
          details: '详情',
        },
        {
          type: '连接件',
          name: '方型垫片',
          scale: '',
          unit: '只',
          label: '膨胀螺丝，化学螺杆垫片',
          details: '详情',
        },
        {
          type: '百叶窗',
          name: '圆形百叶窗',
          scale: '450*450mm',
          unit: '个',
          label: '通风窗、检修窗',
          details: '详情',
        },
        {
          type: '百叶窗',
          name: '方形百叶窗',
          scale: '500*700mm',
          unit: '个',
          label: '通风窗、检修窗',
          details: '详情',
        },
        {
          type: '百叶窗',
          name: '檐口百叶窗',
          scale: '300*300mm',
          unit: '个',
          label: '檐口天花透气窗',
          details: '详情',
        },
      ],
    };
  },
  computed: {
    headerCellStyle() {
      return {
        backgroundColor: 'rgb(122, 73, 13)',
        'text-align': 'center',
        color: '#fff',
      };
    },
    rowStyle() {
      return {
        'text-align': 'center',
        'font-size': '16px',
      };
    },
  },

  methods: {
    arraySpanMethod({ rowIndex, columnIndex }) {
      return FormMerge(rowIndex, columnIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .content {
    width: 85%;
    margin: 0 auto 30px;
    h3 {
      margin: 70px 0 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 28px;
      background-color: rgb(122, 73, 13);
      color: #fff;
      font-weight: bold;
    }
  }
}
::v-deep .el-table__row {
  .el-table__cell:first-child:not([rowspan="1"]) {
    background-color: rgb(122, 73, 13) !important;
    color: #fff;
  }

  .el-table__cell[rowspan="3"]:first-child {
    background-color: rgb(255, 255, 255) !important;
    color: #606266;
  }
  &:nth-last-child(3) {
    .el-table__cell:first-child {
      background-color: rgb(122, 73, 13) !important;
      color: #fff;
    }
  }
}
::v-deep
.el-table--enable-row-hover
.el-table__body
tr:hover > td.el-table__cell {
  background-color: #fff;
}
::v-deep
.el-table--striped
.el-table__body
tr.el-table__row--striped
td.el-table__cell {
  background-color: rgb(248, 203, 173);
}
</style>
