<template>
  <!-- 设计 -->
  <div class="vertex">
    <div class="bannerBox">
      <!-- 背景图 -->
      <div class="txt">
        <!-- 文字 -->
        <h2>
          安雅园建筑新型材料&设计
        </h2>
        <h3>
          Vertex BD
        </h3>
      </div>
    </div>
    <!-- 为什么选用Vertex BD设计软件？ -->
    <div>
      <div class="design">
        <h2 class="title">
          为什么选用Vertex BD设计软件？
        </h2>
        <div class="top">
          <h2>
            1. 高效的设计速率
          </h2>
          <h3>
            HIGHLY EFFICIENT DESIGN RATES
          </h3>
        </div>
        <div class="bottom">
          <p>
            设计自动化，自动创建面板图纸以及各种可配置的材料报告模板，简化设计和生产过程。
          </p>
          <div class="videoBox">
            <XgVideoPlayer video-container="videoFirst" url="https://img.kcgeis.com/4875696096.mp4" />
          </div>
        </div>
      </div>
      <!-- two -->
      <div class="scope">
        <div class="top">
          <h2>
            2. 适用范围广
          </h2>
          <h3>
            WIDE RANGE OF APPLICATIONS
          </h3>
        </div>
        <div class="bottom">
          <p>
            Vertex
            BD是市场上定制性最强的设计软件，用户可自定义添加材料至系统中。且适用于国内生产设备商的产品，通用性强。
          </p>
          <div class="website">
            <template v-for="(item, index) in picList">
              <div :key="index" class="img">
                <a :href="item.url" target="_blank">
                  <img :src="item.img" alt="">
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- three -->
      <div class="bin">
        <div class="top">
          <h2>
            3. BIM协作与文件转换
          </h2>
          <h3>
            BIM COLLABORATION AND FILE CONVERSION
          </h3>
        </div>
        <div class="bottom">
          <p>
            可导入其他设计软件中的二维平面或三维模型，简化详图设计时间。目前支持IFC、PDF、3D
            PDF、DWG等20多种文件格式。
          </p>
          <img src="@/assets/case/13.jpg" alt="">
        </div>
      </div>
      <!-- four -->
      <div class="bin">
        <div class="top">
          <h2>
            4. 消除生产制造错误
          </h2>
          <h3>
            ELIMINATE MANUFACTURING ERRORS
          </h3>
        </div>
        <div class="bottom">
          <p>
            可导入其他设计软件中的二维平面或三维模型，简化详图设计时间。目前支持IFC、PDF、3D
            PDF、DWG等20多种文件格式。
          </p>
          <div class="img">
            <img src="@/assets/case/14.jpg" alt="">
            <img src="@/assets/case/15.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 设计团队简介 / Vertex BD软件运用 -->
    <div>
      <div class="introduce">
        <h2 class="title">
          设计团队简介 / Vertex BD软件运用
        </h2>
        <div class="top">
          <h2>
            1. 安雅园建筑新型材料设计团队
          </h2>
          <h3>
            DESIGN TEAM
          </h3>
        </div>
        <div class="bottom">
          <div class="website">
            <template v-for="(item, index) in teamList">
              <div :key="index" class="img">
                <img :src="item.img" alt="">
                <h2 class="title">
                  {{ item.title }}
                </h2>
                <span>{{ item.txt }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="ao">
        <h2 class="title">
          澳洲项目案例
        </h2>
        <div class="banner">
          <ElCarousel
            :interval="4000"
            type="card"
            height="360px"
            indicator-position="none"
          >
            <template v-for="(item, index) in imgList">
              <ElCarouselItem :key="index">
                <RouterLink :to="{ path: '/case' }">
                  <img :src="item.img">
                </RouterLink>
              </ElCarouselItem>
            </template>
          </ElCarousel>
        </div>
      </div>
      <div class="left">
        <span>
          利用Vertex
          BD软件中有限元（FEA）工具，完善对异形项目设计、受力分析、结构优化，并可出具可靠性验算结果；从而得到问题的解。不仅计算精度高，而且能适应各种复杂形状，因而成为行之有效的工程分析手段。
        </span>
        <div class="c">
          <XgVideoPlayer video-container="videoSecond" url="https://img.kcgeis.com/6682709006.mp4" />
        </div>
      </div>
      <div class="left">
        <div class="c">
          <XgVideoPlayer video-container="videoThird" url="https://img.kcgeis.com/9957995199.mp4" />
        </div>
        <span>
          从客户的体验度以及要求出发，针对不同的用户设计需求以及不同的生产设备，提供更加完善的定制材料库服务，同时可为客户提供完备的项目结构计算书，根据项目情况优化型材配置提供保证结构安全与经济。
        </span>
      </div>
      <!-- 2 -->
      <div class="top">
        <h2>
          2. 独创结构翼缘设计
        </h2>
        <h3>
          ORIGINAL STRUCTURAL AIRFOIL DESIGN
        </h3>
      </div>
      <div class="left">
        <div class="txt">
          <p>
            轻钢墙体的侧向支撑可以分为两大类：普通刚性横撑杆（普通撑杆）和侧装撑杆（翼缘撑杆）。翼缘撑杆是我司所独有的设计构造做法，而相比于普通撑杆做法，翼缘撑杆做法有以下优势：
          </p>
          <h3>
            ① 施工效率及方便性
          </h3>
          <p>
            在拼装过程中翼缘撑杆可直接契合组装，相对普通撑杆所有立柱需要对穿安装，达到了提高效率和施工方便性的目的。
          </p>
          <h3>
            ② 内外墙体错位封板
          </h3>
          <p>
            采用翼缘撑杆的墙体，可以有效解决第二层外墙内外封板错位的问题，内外封板均可按实际需要进行上下移位调整，使所有板材均可方便封板，节约施工工时的同时还可以节省钢拉带用量。
          </p>
          <h3>
            ③ 灌浆墙体错位流动性
          </h3>
          <p>
            对于灌浆实心墙体，采用翼缘撑杆可以使墙体灌浆浆体在墙体内部上下自由流动。
          </p>
        </div>
        <img src="@/assets/case/31.jpg" alt="">
      </div>
      <!-- 3 -->
      <div class="top">
        <h2>
          3. 运用Vertex BD软件中的高级建模和渲染功能
        </h2>
        <h3>
          UTILIZE THE ADVANCED MODELING AND RENDERING FEATURES IN VERTEX BD
          SOFTWARE
        </h3>
      </div>
      <div class="right">
        <p>
          ① 我司设计人员，运用Vertex
          BD软件中的高级建模和渲染功能，可将轻钢结构房屋中所需用到的材料配件，例如：角码、角铁、檐口折件、化学锚栓等结构小型配件，以及天沟落水系统、楼面钢板网、楼承板等辅助材料，通过组成建筑组件，并在设计建模过程中模拟其使用位置并通过渲染功能导出成高清图片，可随时发送给客户或施工现场人员，解决了施工现场因结构配件使用不明确而无法使用至正确位置的问题，改善了轻钢结构加固以及材料铺张浪费的整体情况
        </p>
        <p>
          ②
          我司通过长时间建筑和结构设计的经验积累以及客户回访满意度、调查市场上轻钢建筑施工队对于材料配置使用位置不明确的问题，致力于改善轻钢行业中不足及缺陷，方便客户及施工队，因此开展了“图鉴项目”研发工作。由公司总经理带头，技术部总监全权负责，通过筛选优质项目效果图鉴及平面方案，以及运用Vertex
          BD软件中的高级建模和渲染功能，将轻钢别墅分系统，将所用到的结构配件，通过结构类型的不同合理运用，并参考国家规范中的样式渲染成高清图片，最终完成《轻钢别墅设计图鉴》。
        </p>
        <p>
          《轻钢别墅设计图鉴》共由两大部分组成。
          第一部分为：建筑篇包含室外工程、楼地面工程、外墙工程、外墙保温工程，内墙顶棚工程、屋面工程、门窗工程以及加固工程，图集以住宅为基础进行全方位的剖析建筑做法，囊括范围广、详尽齐全，相比文字性的做法可更加直观的查看细节做法。
          第二部分为：轻钢结构三维节点大样。共分为结构常用构件材料、墙体系统、楼面系统、屋面系统和楼梯系统，每个系统分别将对应的做法以及所要用到的结构配件及螺钉均以三维图示展现出来，更能直观的展现每个材料的用法，方便客户及施工队对于结构材料选择和运用。
        </p>
        <img src="@/assets/case/32.jpg" alt="">
      </div>
      <!-- 4 -->
      <div class="top">
        <h2>
          4. 开发Vertex BD软件中其他特色功能
        </h2>
        <h3>
          DEVELOP OTHER FEATURES IN VERTEX BD SOFTWARE
        </h3>
      </div>
      <div class="bottom">
        <span>
          我司长期与客户或施工队交流学习，为解决项目现场施工中所造成的不必要的板材浪费以及提高预算结果的精准度，为客户提供最具有性价比的预算造价。设计人员运用Vertex
          BD软件中的板材功能，逐渐实现了从墙体、楼面再到天花和屋面的结构板预排板机制，所有位置板材的切割尺寸以及所使用的位置均能在Vertex
          BD软件中体现出来，由此对于具体项目板材的分类和预算数量做出了更加准确的统计。
        </span>
        <div class="c">
          <XgVideoPlayer video-container="videoFourth" url="https://img.kcgeis.com/9180957722.mp4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import XgVideoPlayer from '@/components/XgVideoPlayer.vue';

export default {
  name: 'VertexIndex',
  components: {
    XgVideoPlayer,
  },
  data() {
    const imgList = [
      { img: require('@/assets/case/ao1.jpg') },
      { img: require('@/assets/case/ao2.jpg') },
      { img: require('@/assets/case/ao3.jpg') },
    ];
    const picList = [
      {
        img: require('@/assets/case/1.jpg'),
        url: 'https://www.amscontrols.com/',
      },
      {
        img: require('@/assets/case/2.jpg'),
        url: 'https://en.arkitech.com.tr/',
      },
      {
        img: require('@/assets/case/3.jpg'),
        url: 'https://www.asc-uk.net/',
      },
      {
        img: require('@/assets/case/4.jpg'),
        url: 'https://www.beckautomation.com/',
      },
      {
        img: require('@/assets/case/5.jpg'),
        url: 'https://boracom.ru/reguliruemye-kompleksy-lstk/',
      },
      {
        img: require('@/assets/case/6.jpg'),
        url: '#',
      },
      {
        img: require('@/assets/case/7.jpg'),
        url: 'https://www.dahezbforming.com/',
      },
      {
        img: require('@/assets/case/8.jpg'),
        url: 'http://en.framemac.com/',
      },
      {
        img: require('@/assets/case/9.jpg'),
        url: 'https://www.howickltd.com/',
      },
      {
        img: require('@/assets/case/10.jpg'),
        url: 'about:blank',
      },
      {
        img: require('@/assets/case/11.jpg'),
        url: '#',
      },
      {
        img: require('@/assets/case/12.jpg'),
        url: 'http://www.xmliming.com.cn/en/about.aspx',
      },
    ];
    const teamList = [
      {
        img: require('@/assets/case/21.jpg'),
        title: '人员规模',
        txt:
          '公司自2018年成立以来，力争以技术为核心的中心思想，并在轻钢设计领域不断学习与探索。现已有二十人以上的规模。',
      },
      {
        img: require('@/assets/case/22.jpg'),
        title: '人员成分',
        txt:
          '一级注册结构工程师2名,二级注册结构工程师1名,一级造价工程师1名,一级建造师1名,咨询工程师1名,',
      },
      {
        img: require('@/assets/case/23.jpg'),
        title: '人员培训',
        txt:
          '项目案例探讨从项目问题分析根据国家规范、专业知识学习；积极参加行业内论坛及相关培训业内新动态分享等。',
      },
      {
        img: require('@/assets/case/24.jpg'),
        title: '理论实践',
        txt:
          '参加Vertex BD官方举办的基础培训及进阶培训；邀请澳大利亚著名设计师来公司对设计师进行设计培。',
      },
      {
        img: require('@/assets/case/25.jpg'),
        title: '项目推进',

        txt:
          '开始设计前，均由负责人牵头，对项目方案可行性进行论证，避免可能存在的薄弱环节，并给出相对应的解决方案，确保项目的安全性。',
      },
      {
        title: '项目处理能力',
        img: require('@/assets/case/26.jpg'),
        txt:
          '设计团队输出能力为单日设计项目数6到9项，最高输出为月饱和处理能力为5～6万平方米，针对突发事件有充足人员应对。',
      },
    ];

    return {

      picList,
      teamList,
      imgList,
    };
  },

  computed: {
    // videoList() {
    //   return [
    //     { src: 'https://img.kcgeis.com/4875696096.mp4' },
    //     { src: 'https://img.kcgeis.com/6682709006.mp4' },
    //     { src: 'https://img.kcgeis.com/9957995199.mp4' },
    //     { src: 'https://img.kcgeis.com/9180957722.mp4' },
    //   ];
    // },
  },
};
</script>

<style lang="scss" scoped>
.vertex {
  .bannerBox {
    width: 100%;
    height: 500px;
    background: url('https://img.kcgeis.com/6391796501.jpg');
    .txt {
      position: absolute;
      top: 260px;
      left: 350px;
      h2 {
        font-size: 60px;
        color: #018835;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        text-align: left;
      }
    }
  }
  .top {
    margin: 40px 0;
    h2 {
      font-size: 30px;
      font-weight: bold;
      color: #444;
      text-align: center;
    }
    h3 {
      font-size: 16px;
      color: #aaa;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .title {
    font-size: 30px;
    color: #018835;
    margin-top: 40px;
    font-weight: bold;
    text-align: center;
  }
  .design {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url("https://img.kcgeis.com/2257019649.jpg") no-repeat center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom {
      p {
        margin-bottom: 40px;
      }
      margin: 0 auto 40px;
      width: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .scope {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 30px;
      color: #018835;
      margin: 40px 0 40px;
      font-weight: bold;
    }

    .bottom {
      p {
        margin-bottom: 40px;
      }
      margin: 0 auto;
      width: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .website {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .img {
          border: 1px solid #eee;
          padding: 10px;
          margin-bottom: 50px;
          border-radius: 25px;
        }
      }
    }
  }
  .bin {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 30px;
      color: #018835;
      margin-top: 40px;
      font-weight: bold;
    }

    .bottom {
      margin: 0 auto 40px;
      width: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        margin-bottom: 40px;
      }
      .img {
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
          width: 500px;
        }
      }
    }
  }
  .introduce {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 30px;
      color: #018835;
      font-weight: bold;
    }

    .bottom {
      p {
        margin-bottom: 40px;
      }
      margin: 0 auto;
      width: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .website {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 26%;
          height: 300px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h2 {
            margin: 0;
          }
          span {
            font-size: 14px;
            color: #666;
            line-height: 1.8;
            width: 200px;
          }
        }
      }
    }
  }
  .banner {
    background: url("~@/assets/case/banner.jpg") no-repeat center;
    height: 550px;
    margin: 50px 0;
    padding: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 25px;
    }
  }
  .left {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    span {
      margin: 0 124px;
      width: 460px;
      color: #666;
    }
    .c {
      width: 400px!important;
      // height: 200px;
    }
    .txt {
      width: 460px;
      h3 {
        font-size: 20px;
        color: #018835;
        margin: 10px 0;
      }
      p {
        font-size: 20px;
        color: #666;
      }
    }
  }
  .right {
    width: 1100px;
    margin: 0 auto;
    p {
      font-size: 18px;
      color: #666;
      margin-bottom: 20px;
    }
  }
  .bottom {
    display: flex;
    width: 1200px;
    margin: 0 auto 100px;
    justify-content: space-around;
    align-items: center;
    span {
      width: 460px;
      color: #666;
    }
    .c {
      width: 400px;
      height: 200px;
    }
  }
  .videoBox {
    width: 1000px;
  }
}
::v-deep .el-carousel__mask {
  border-radius: 25px;
}
</style>
