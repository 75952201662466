<template>
  <Component :is="currentTabComponent" />
</template>

<script>
import caseShow from '@/views/caseCenter/Case';
import vertexBD from '@/views/caseCenter/VertexBD';
import product from '@/views/caseCenter/Product';
import provision from '@/views/caseCenter/Provision';

export default {
  name: 'CaseCenterIndex',
  components: {
    caseShow,
    product,
    provision,
    vertexBD,
  },
  data() {
    return {
      currentTabComponent: caseShow,
      classList: [
        {
          // "产品展示",
          component: caseShow,
        },
        {
          // "Vertex BD设计",
          component: vertexBD,
        },
        {
          // "轻钢龙骨生产",
          component: product,
        },
        {
          // "建筑材料供应",
          component: provision,
        },

      ],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newV) {
        this.changeTab(newV.index ?? 0);
      },
    },
  },

  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component;
    },
  },
};
</script>

<style lang="scss" scoped></style>
