export function FormMerge(rowIndex, columnIndex) {
  if (columnIndex === 0) {
    if (rowIndex === 0) {
      return {
        rowspan: 13,
        colspan: 1,
      };
    }
    if (rowIndex === 1) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 2) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 3) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 4) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 5) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 6) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 7) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 8) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 9) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 10) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 11) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 12) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 13) {
      return {
        rowspan: 6,
        colspan: 1,
      };
    }
    if (rowIndex === 14) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 15) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 16) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 17) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 18) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 19) {
      return {
        rowspan: 6,
        colspan: 1,
      };
    }
    if (rowIndex === 20) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 21) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 22) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 23) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 24) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 25) {
      return {
        rowspan: 6,
        colspan: 1,
      };
    }
    if (rowIndex === 26) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 27) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 28) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 29) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 30) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 31) {
      return {
        rowspan: 15,
        colspan: 1,
      };
    }
    if (rowIndex === 32) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 33) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 34) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 35) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 36) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 37) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 38) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 39) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 40) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 41) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 42) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 43) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 44) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 45) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 46) {
      return {
        rowspan: 18,
        colspan: 1,
      };
    }
    if (rowIndex === 47) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 48) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 49) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 50) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 51) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 52) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 53) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 54) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 55) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 56) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 57) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 58) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }

    if (rowIndex === 59) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 60) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 61) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 62) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 63) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 64) {
      return {
        rowspan: 20,
        colspan: 1,
      };
    }
    if (rowIndex === 65) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 66) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 67) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 68) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 69) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 70) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 71) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 72) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 73) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 74) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 75) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 76) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 77) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 78) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 79) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 80) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 81) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 82) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 83) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 84) {
      return {
        rowspan: 14,
        colspan: 1,
      };
    }
    if (rowIndex === 85) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 86) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 87) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 88) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 89) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 90) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 91) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 92) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 93) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 94) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 95) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 96) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 97) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 98) {
      return {
        rowspan: 10,
        colspan: 1,
      };
    }
    if (rowIndex === 99) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 100) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 101) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 102) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 103) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 104) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 105) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 106) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 107) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 108) {
      return {
        rowspan: 3,
        colspan: 1,
      };
    }
    if (rowIndex === 109) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 110) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }
  if (columnIndex === 1) {
    if (rowIndex === 13) {
      return {
        rowspan: 3,
        colspan: 1,
      };
    }
    if (rowIndex === 14) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 15) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 16) {
      return {
        rowspan: 3,
        colspan: 1,
      };
    }
    if (rowIndex === 17) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 18) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 19) {
      return {
        rowspan: 3,
        colspan: 1,
      };
    }
    if (rowIndex === 20) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
    if (rowIndex === 21) {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }
}
export default {
  FormMerge,
};
