<template>
  <!-- 案例展示 -->
  <div class="case">
    <Banner v-bind="bannerProps" />
    <!-- 正文 -->
    <div v-loading="fullscreenLoading" class="caseBox">
      <!-- 分类按钮 -->
      <div class="classBtnBox">
        <div class="left">
          层高：
        </div>
        <div class="a">
          <div
            class="right"
            :class="{ active: highindex == null }"
            @click="allHigh"
          >
            全部
          </div>
          <div class="right-box">
            <template v-for="(item, index) in caseHighList">
              <div
                :key="item.id"
                :class="{ active: index == highindex }"
                class="right"
                @click="onCaseHigh(item.id, index)"
              >
                {{
                  item.caseHighName
                }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">
          户型：
        </div>
        <div class="a">
          <div
            class="right"
            :class="{ active: houseindex == null }"
            @click="allHouse"
          >
            全部
          </div>
          <div class="right-box">
            <template v-for="(item, index) in caseHouseList">
              <div
                :key="item.id"
                :class="{ active: index == houseindex }"
                class="right"
                @click="onCaseHouse(item.id, index)"
              >
                {{
                  item.caseHouseName
                }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">
          面积：
        </div>
        <div class="a">
          <div
            class="right"
            :class="{ active: areaindex == null }"
            @click="allArea"
          >
            全部
          </div>
          <div class="right-box">
            <template v-for="(item, index) in caseAreaList">
              <div
                :key="item.id"
                :class="{ active: index == areaindex }"
                class="right"
                @click="onCaseArea(item.id, index)"
              >
                {{
                  item.caseAreaName
                }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="classBtnBox">
        <div class="left">
          风格：
        </div>
        <div class="a">
          <div
            class="right"
            :class="{ active: styleindex == null }"
            @click="allStyle"
          >
            全部
          </div>
          <div class="right-box">
            <template v-for="(item, index) in caseStyleList">
              <div
                :key="item.id"
                :class="{ active: index == styleindex }"
                class="right"
                @click="onCaseStyle(item.id, index)"
              >
                {{
                  item.caseStyleName
                }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- 案例 -->
      <ElRow id="routerhash">
        <ElCol v-for="item in caseList" :key="item.id" :span="8">
          <RouterLink :to="{ name: 'CaseContent', query: { id: item.id } }">
            <ElCard :body-style="{ padding: '0px' }">
              <div class="img">
                <img :src="item.imgUrl" class="image">
              </div>
              <div class="wenzi">
                <div class="left">
                  <span>{{ item.caseTitle }}</span>
                  <time class="time">
                    {{ item.description }}
                  </time>
                </div>
                <div class="button">
                  查看<br>详情
                </div>
              </div>
            </ElCard>
          </RouterLink>
        </ElCol>
      </ElRow>

      <!-- 分页按钮 -->
      <PaginationButton
        v-show="total > limit"
        :total="total"
        :page="pages"
        :limit="limit"
        @currentChange="currentChange"
      />
    </div>
  </div>
</template>

<script>
import PaginationButton from '@/components/Pagination.vue';
import Banner from '@/components/banner.vue';

import {
  caseClass,
  caseInfo,
  caseHigh,
  caseHouse,
  caseArea,
  caseStyle,
} from '@/api/case';

export default {
  name: 'CaseIndex',
  components: {
    Banner,
    PaginationButton,
  },
  data() {
    return {
      fullscreenLoading: false,
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/7008683327.jpg',
      },
      btnindex: null,
      highindex: null,
      houseindex: null,
      areaindex: null,
      styleindex: null,
      id: '',
      classBtnList: [],
      caseHighList: [],
      caseHouseList: [],
      caseAreaList: [],
      caseStyleList: [],
      caseList: [],
      limit: 9,
      pages: 1,
      classId: '',
      total: 0,
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      deep: true,
      handler(newV) {
        this.onClassBtn(newV);
      },
    },
  },
  async created() {
    await this.getClass();
    await this.caseHigh();
    await this.caseHouse();
    await this.caseArea();
    await this.caseStyle();
    await this.getData();
  },
  methods: {
    currentChange(e) {
      this.pages = e;
      this.getData();
    },
    allClass() {
      this.btnindex = null;
      this.classId = '';
      this.getData();
    },
    allHigh() {
      this.highindex = null;
      this.highId = '';
      this.getData();
    },
    allHouse() {
      this.houseindex = null;
      this.houseId = '';
      this.getData();
    },
    allArea() {
      this.areaindex = null;
      this.areaId = '';
      this.getData();
    },
    allStyle() {
      this.styleindex = null;
      this.styleId = '';
      this.getData();
    },

    onClassBtn(id) {
      this.pages = 1;
      this.classId = id;
      this.getData();
    },
    onCaseHigh(id, index) {
      this.highindex = index;
      this.pages = '';
      this.highId = id;
      this.getData();
    },
    onCaseHouse(id, index) {
      this.houseindex = index;
      this.pages = '';
      this.houseId = id;
      this.getData();
    },
    onCaseArea(id, index) {
      this.areaindex = index;
      this.pages = '';
      this.areaId = id;
      this.getData();
    },
    onCaseStyle(id, index) {
      this.styleindex = index;
      this.pages = '';
      this.styleId = id;
      this.getData();
    },
    async getClass() {
      try {
        const classData = await caseClass();
        this.classBtnList = classData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async caseHigh() {
      try {
        const caseHighData = await caseHigh();
        this.caseHighList = caseHighData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async caseHouse() {
      try {
        const caseHouseData = await caseHouse();
        this.caseHouseList = caseHouseData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async caseArea() {
      try {
        const caseAreaData = await caseArea();
        this.caseAreaList = caseAreaData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async caseStyle() {
      try {
        const caseStyleData = await caseStyle();
        this.caseStyleList = caseStyleData.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    async getData() {
      this.fullscreenLoading = true;
      try {
        const params = {
          caseClassId: this.classId,
          caseAreaId: this.areaId,
          caseHighId: this.highId,
          caseHouseId: this.houseId,
          caseStyleId: this.styleId,
          limit: this.limit,
          pages: this.pages,
        };
        const infoData = await caseInfo(params);
        this.caseList = infoData.data.records;
        this.total = infoData.data.total;
      } catch (error) {
        this.$message.error(error);
      }
      this.fullscreenLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 100%;
  .caseBox {
    width: 1200px;
    margin: 40px auto 0;
   
    .classBtnBox {
      border-bottom: 1px #e5e5e5 solid ;
      border-right: 1px #e5e5e5 solid ;
      border-left: 1px #e5e5e5 solid ;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 0;
      background-color: #fafafa;
      &:first-child {
        border-top: 1px #e5e5e5 solid ;
      }
      .left {
        min-width: 100px;
        font-size: 12px;
        line-height: 40px;
        color: #666;
        border-right: #e5e5e5 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .a {
        flex: 1;
        display: flex;
        padding-left: 10px;

        .right-box {
          display: flex;
          flex: 1;
          flex-wrap: wrap;
        }
        
        .right {
          margin: 5px;
          font-size: 12px;
          color: #666;
          white-space: normal;
          transition: all 0.5s;
          padding: 5px;
          border-radius: 5px;
          height: 26px;

          &:hover,
          &.active {
            cursor: pointer;
            background-color: #018835;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
   
    .el-row {
      margin-top: 30px;
      .el-card {
        margin: 20px;

        .img {
          height: 300px;
          overflow: hidden;
        }
        .image {
          height: 100%;
          width: 100%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.1);
            transition: all 0.5s;
          }
        }
      }
      .wenzi {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            font-size: 14px;
            color: #666;
          }
          .time {
            font-size: 14px;
            color: #9fa19f;
          }
        }
        .button {
          font-size: 15px;
          color: #fff;
          line-height: 1.1;
          font-weight: bold;
          padding: 15px;
          background: #018835;
        }
      }
    }
  }
}
</style>
